<template>
  <header>
    <!-- Logo and contact info -->
    <div
      class="container-fluid-lg flex items-center justify-center pt-4 text-sm font-light text-txt-strong md:justify-between"
    >
      <WebccCompanyLogo
        target="_self"
        :anchor="false"
        :caption="logoCaption"
        class="hidden md:block"
        :href="logoHref"
        external
        @click="tracking.handlers?.header.headerItemClick('homepage')"
      />
      <aside
        v-if="siteSettings?.includes('contactinfo')"
        class="pointer-events-none flex items-center"
      >
        <span class="pointer-events-auto">
          {{ $t(`${trPrefix}hereForYou`) }}
        </span>

        <WebccLink
          v-if="showContactUs && links?.contact?.href"
          :to="links.contact.href"
          class="pointer-events-auto ml-3 underline underline-offset-1"
          external
          @click="tracking.handlers?.header.headerItemClick('contactus')"
        >
          {{ $t(`${trPrefix}contactUs`) }}
        </WebccLink>
      </aside>
    </div>

    <!-- Navigation, Bookmarks, Lang and Currency switcher -->
    <div
      class="container-fluid-lg flex items-center py-2 pb-4"
      :class="showMenu ? 'justify-between' : 'justify-end'"
    >
      <WebccCompanyLogo
        target="_self"
        :anchor="false"
        :caption="logoCaption"
        class="mr-auto md:hidden"
        :href="logoHref"
        external
        @click="tracking.handlers?.header.headerItemClick('homepage')"
      />
      <Navigation
        v-if="showMenu && header?.menu && links"
        :menu="header?.menu"
        :links="links"
      />
      <div class="flex items-center md:items-end">
        <!-- Bookmarks -->
        <client-only>
          <WebccLink
            v-if="useStorageReady()"
            class="relative ml-4 flex rounded border border-bgr-300 p-2 text-sm text-txt-strongest duration-200 hover:bg-bgr-100 focus:outline-none md:ml-3 cursor-pointer"
            external
            @click="navigateToWishlists"
          >
            <WebccIcon
              class="mr-0 h-5 w-5 text-bgr-600 md:mr-1"
              name="site/bookmark"
              filled
            />

            <span class="hidden md:block">
              {{ $t(`${trPrefix}labels.favorites`) }}
            </span>
          </WebccLink>
        </client-only>
        <!-- Language Switcher -->
        <HeaderSelect
          v-if="sites?.length && sites?.length > 1"
          :selected-value="language!"
          :all-options="sites!"
          :has-key="true"
          object-key="language"
          :to-upper-case="true"
          @on-change="changeLanguage"
        />
        <!-- Currency Switcher -->
        <HeaderSelect
          v-if="currencies && currencies.length > 1"
          :selected-value="confCurrency!"
          :all-options="currencies!"
          :has-key="false"
          :to-upper-case="true"
          @on-change="changeCurrency"
        />
        <HeaderUserButton v-if="isAuth" class="ml-4" />
        <WebccLink
          v-else
          class="relative ml-3 flex rounded-full border border-bgr-300 py-2 px-3 text-sm text-txt-strongest duration-200 hover:bg-bgr-100 focus:outline-none md:ml-4"
          anchor
          @click.stop.prevent="signIn"
        >
          <span class="text-nowrap text-medium font-medium">
            {{ $t(`${trPrefix}labels.signIn`) }}
          </span>
        </WebccLink>
        <!-- Mobile Menu Button -->
        <button
          class="ml-2 rounded p-1 hover:bg-bgr-100 focus:outline-none active:bg-bgr-100 sm:ml-2 sm:p-2 md:hidden"
          @click.stop="toggling.toggle('menu')"
        >
          <WebccIcon class="h-7 w-7 text-txt-weak" name="site/menu" />
        </button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

const trPrefix = 'guestWorld.headerB2C.'

withDefaults(
  defineProps<{
    showMenu?: boolean
    showContactUs?: boolean
  }>(),
  {
    showMenu: true,
    showContactUs: true,
  },
)

// Imports and constants
const logoHref: Ref<string> = ref(
  getHomeNavigation(
    '/',
    useParams().removeQueryParams(useRoute().query, [
      'code',
      'flow',
      'email',
      'returnTo',
    ]),
  ),
)
const {
  header,
  links,
  currencies,
  language,
  siteSettings,
  sites,
  logoCaption,
  currency: confCurrency,
} = storeToRefs(useConfdata())
const tracking = useTracking()
const toggling = useToggler()
const { buildLoginUrl } = useURLs()
const isAuth = await useAuth().isAuthenticated

async function navigateToWishlists() {
  await navigateTo({
    path: '/myaccount/wishlists',
    query: { ...useRoute().query },
  })
}

async function signIn() {
  await navigateTo(buildLoginUrl().toString(), { external: true })
}

// Change the selected language
function changeLanguage(language: string) {
  tracking.handlers?.header.languageSwitch(language)

  // Construct the new URL
  const url = useRequestURL()

  // Set the language parameter
  url.searchParams.set('language', language)
  window.location.href = url.href
}

function changeCurrency(currency: string) {
  tracking.handlers?.header.currencySwitch(currency)
  const url = new URL(window.location.href)
  url.searchParams.set('currency', currency)
  window.location.href = url.href
}
</script>
