<template>
  <!-- Mobile menu -->
  <div class="absolute inset-0 z-[1000] flex flex-col bg-bgr md:hidden">
    <div
      v-if="siteSettings?.includes('contactinfo')"
      class="pointer-events-none flex items-center justify-center pt-4 text-sm font-light text-txt-strong"
    >
      <span class="pointer-events-auto">
        {{ $t(`${trPrefix}hereForYou`) }}
      </span>
      <WebccLink
        :to="props.links.contact?.href"
        class="pointer-events-auto ml-3 underline underline-offset-1"
        @click="tracking.handlers?.header.headerItemClick('contactus')"
      >
        {{ $t(`${trPrefix}contactUs`) }}
      </WebccLink>
    </div>
    <div class="flex justify-end border-b border-bgr-200 p-3">
      <button
        class="rounded p-1 hover:bg-bgr-100 focus:outline-none active:bg-bgr-100 sm:ml-2 sm:p-2 md:hidden"
        @click.stop="toggling.toggle('menu')"
      >
        <WebccIcon class="h-7 w-7 text-txt-weak" name="site/x" />
      </button>
    </div>
    <ul class="mt-3 flex flex-col space-y-4">
      <li
        v-for="item in props.menu"
        :key="item.id + 'link'"
        class="whitespace-no-wrap cursor-pointer rounded text-txt-strongest hover:bg-bgr-100"
      >
        <WebccLink
          anchor
          :to="item.href"
          :hash="item.hash"
          :target="item.target"
          class="block px-4 py-2 pb-4 md:text-center"
          @click="
            tracking.handlers?.header.sitenavigationClick(
              item.id as
                | 'partner'
                | 'lso'
                | 'topcountries'
                | 'inspirations'
                | 'homeowner',
            )
          "
          >{{ item.label }}</WebccLink
        >
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

const trPrefix = 'guestWorld.headerB2C.'
const { siteSettings } = storeToRefs(useConfdata())
const toggling = useToggler()
const tracking = useTracking()

const props = defineProps<{
  menu: HeaderMenuItem[]
  links: Links
}>()

onMounted(() => {
  const cW = document.body.clientWidth
  document.documentElement.classList.add('!overflow-y-hidden')
  document.documentElement.style.width = `${cW}px`
})

onBeforeUnmount(() => {
  document.documentElement.classList.remove('!overflow-y-hidden')
  document.documentElement.style.width = ''
})
</script>
