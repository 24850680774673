<template>
  <div class="relative items-center">
    <!-- User Button -->
    <div
      class="flex items-center cursor-pointer transition-all hover:opacity-90 active:scale-95 duration-300"
      @click.stop="openMenu"
    >
      <div
        class="size-10 rounded-full overflow-hidden bg-thm flex items-center justify-center"
      >
        <img
          v-if="profileImg"
          :src="'data:image/png;base64,' + profileImg"
          alt=""
        />
        <WebccIcon v-else name="account/user" class="size-6 text-bgr" />
      </div>
    </div>

    <!-- Desktop Menu -->
    <Transition name="bounce">
      <div
        v-if="desktopMenu"
        v-on-click-outside.bubble="() => (desktopMenu = false)"
        class="absolute z-5 right-0 bottom-0 bg-bgr rounded shadow-lg translate-y-full flex flex-col"
      >
        <div v-if="signedIn" class="flex gap-4 px-3 py-4">
          <div
            class="size-11 rounded-full overflow-hidden bg-thm flex items-center justify-center"
          >
            <img
              v-if="profileImg"
              :src="'data:image/png;base64,' + profileImg"
              alt=""
            />
            <span v-else-if="initials" class="text-lg lg:text-2xl text-bgr">{{
              initials
            }}</span>
            <WebccIcon v-else name="account/user" class="size-6 text-bgr" />
          </div>
          <div class="flex flex-col">
            <span class="font-medium">{{ displayedName }}</span>
            <span class="text-sm text-txt-weak">{{ email }}</span>
          </div>
        </div>
        <div class="flex flex-col border-y">
          <button
            class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
            @click="toProfile"
          >
            {{ $t(`${trPrefix}myAccount`) }}
          </button>
          <button
            class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
            @click="toBookings"
          >
            {{ $t(`${trPrefix}myBookings`) }}
          </button>
        </div>
        <button
          class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-err text-left"
          @click="logout"
        >
          Log out
        </button>
      </div>
    </Transition>

    <!-- Mobile Menu -->
    <ClientOnly>
      <DrawerRoot v-model:open="mobileMenu.isOpen">
        <DrawerPortal>
          <DrawerOverlay class="fixed bg-black/40 inset-0" />
          <DrawerContent
            aria-describedby="undefined"
            class="bg-bgr flex justify-center rounded-t-lg h-full max-h-[90%] fixed bottom-0 left-0 right-0"
          >
            <DrawerTitle />
            <div v-if="!uploadAvatarState" class="max-w-xl w-full">
              <div class="w-full flex justify-center pt-2.5">
                <WebccIcon
                  name="site/chevron-down"
                  filled
                  class="size-8 inline text-txt-weaker"
                />
              </div>
              <div class="p-4 flex flex-col">
                <UserCard
                  :desktop="false"
                  :mobile="true"
                  @remove-avatar="onRemoveAvatar"
                  @upload-avatar-mobile="onUploadAvatar"
                />
                <div class="flex flex-col border-b mt-4">
                  <button
                    class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
                    @click="toProfile"
                  >
                    {{ $t(`${trPrefix}myAccount`) }}
                  </button>
                  <button
                    class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
                    @click="toBookings"
                  >
                    {{ $t(`${trPrefix}myBookings`) }}
                  </button>
                </div>
                <button
                  class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-err text-left"
                  @click="logout"
                >
                  {{ $t(`${trPrefix}logOut`) }}
                </button>
              </div>
            </div>
            <div v-if="uploadAvatarState" class="max-w-xl w-full">
              <UploadAvatar
                v-if="uploadAvatarState"
                :desktop="false"
                :mobile="true"
                @close="closeMenu"
              />
            </div>
          </DrawerContent>
        </DrawerPortal>
      </DrawerRoot>
    </ClientOnly>
    <Teleport to="body">
      <WebccLoaderOverlay v-if="requestPending" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import type { LogoutFlow } from '@ory/client'
import { vOnClickOutside } from '@vueuse/components'
import {
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
  DrawerTitle,
} from 'vaul-vue'
import { useI18n } from '~/composables/@ihgroup/l10n/useI18n'

const trPrefix = 'guestWorld.headerB2C.headerUserButton.'

const desktopMenu = ref(false)
const mobileMenu = useUserMobileMenu()
const auth = useAuth()
const userStore = useUserStore()
const { t } = useI18n()

const { signedIn, email, initials, profileImg, uploadAvatarState } =
  toRefs(userStore)
const isAuthenticated = await auth.isAuthenticated

const displayedName = computed(() =>
  useUser().name ? useUser().name : t(`${trPrefix}user`),
)

async function toProfile() {
  const url = `/myaccount/profile${getQueryStringFromObject(useRoute().query)}`
  await navigateTo(url)
  closeMenu()
}

async function toBookings() {
  const url = `/myaccount/bookings${getQueryStringFromObject(useRoute().query)}`
  await navigateTo(url)
  closeMenu()
}

function onUploadAvatar() {
  uploadAvatarState.value = true
}

function openMenu() {
  if (!useScreenStore().current || useScreenStore().current === 'sm') {
    mobileMenu.open()
  } else {
    desktopMenu.value = !desktopMenu.value
  }
}

function closeMenu() {
  if (!useScreenStore().current || useScreenStore().current === 'sm') {
    mobileMenu.close()
    uploadAvatarState.value = false
  } else {
    desktopMenu.value = false
  }
}

function onRemoveAvatar() {
  requestPending.value = !requestPending.value
}

const logoutData: Ref<LogoutFlow | undefined> = ref(undefined)
const requestPending = ref(false)
onMounted(async () => {
  if (isAuthenticated && !userStore.userData) {
    userStore.load()
  }
  logoutData.value = await useAuth().getLogoutData()
})
onUnmounted(() => {
  requestPending.value = false
})

async function logout() {
  if (!logoutData.value) return
  requestPending.value = true
  try {
    await useAuth().logoutUser(logoutData.value.logout_token)
  } catch (error) {
    console.error(error)
    return
  }
  const query = useParams().removeQueryParams(useRoute().query, [
    'code',
    'flow',
    'email',
    'returnTo',
  ])
  await navigateTo({ path: '/login', query })
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.2s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(5%, 95%) scale(90%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 100%) scale(100%);
    opacity: 1;
  }
}
</style>
