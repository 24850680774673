<template>
  <div class="flex min-h-screen flex-col text-txt-strongest">
    <Colors :colors="colors" />
    <HeaderB2C />
    <span class="h-px bg-bgr-300"></span>
    <div
      class="container-fluid-lg min-h-3/4-screen flex flex-col md:flex-row pb-20"
    >
      <slot />
    </div>
    <Footer
      :footer-links="navigation?.links"
      class="print:hidden"
      :logo-caption="logoCaption"
    />
  </div>
</template>

<script lang="ts" setup>
const { logoCaption, navigation } = useConfdata()
const { colors } = useColors()
</script>

<style>
.account-page-enter-active,
.account-page-leave-active {
  transition: all 0.2s;
}
.account-page-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}
.account-page-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
